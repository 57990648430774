<template>
  <div id="app">
    <!-- 头部 -->
    <div class="header">
      <!-- 背景 -->
      <img class="back" src="./assets/back1.jpg" alt="" />
      <!-- 悬浮 -->
      <div
        class="nav"
        :style="{ top: nav + 'px', width: width + 'px', height: height + 'px' }"
        :class="{ fixed }"
      >
        <!-- 锚点 -->
        <a href="#p1">
          <img
            :style="{ top: navTop + 'px' }"
            class="nav1"
            src="./assets/nav1.png"
            alt=""
          />
        </a>
        <a href="#p2">
          <img
            :style="{ top: navTop + 'px' }"
            class="nav2"
            src="./assets/nav2.png"
            alt=""
          />
        </a>
        <a href="#p3">
          <img
            :style="{ top: navTop + 'px' }"
            class="nav3"
            src="./assets/nav3.png"
            alt=""
          />
        </a>
      </div>

      <!-- prat1介绍 -->
      <div id="p1" :style="{ top: `${descTop - height}px` }"></div>
      <img
        :style="{ top: descTop + 'px' }"
        class="part-img"
        src="./assets/desc.png"
        alt=""
      />
      <img
        :style="{ top: teamTop + 'px' }"
        class="part-img"
        src="./assets/team.png"
        alt=""
      />
    </div>
    <!-- 头部 end -->
    <!-- 区块2 -->
    <div class="part2">
      <div id="p2" :style="{ top: `-${height}px` }"></div>
      <img class="img" src="./assets/class.png" alt="" />
      <img
        class="img"
        :style="{ margin: `${mar1}px 0 ${mar2}px` }"
        src="./assets/vlight1.png"
        alt=""
      />
      <img
        @click="showVideo(), (v2 = false), (v1 = true)"
        class="img"
        :style="{ marginBottom: `${mar3}px` }"
        src="./assets/vlight2.png"
        alt=""
      />
      <img
        class="img"
        :style="{ marginBottom: `${mar4}px` }"
        src="./assets/vlight3.png"
        alt=""
      />
      <img class="img" src="./assets/video.png" alt="" />
    </div>
    <!-- 区块2 end -->
    <!-- 区块3 -->

    <div class="part3">
      <div id="p3" :style="{ top: `-${height}px` }"></div>
      <img
        class="img"
        :style="{ marginTop: `${mar5}px` }"
        src="./assets/51.png"
        alt=""
      />
      <div class="v2play">
        <img
          class="img"
          :style="{ margin: `${mar6}px 0 ${mar7}px` }"
          src="./assets/content.png"
          alt=""
        />
        <div
          @click="showVideo(), (v2 = true), (v1 = false)"
          class="play"
          :style="{
            top: playTop + 'px',
            width: playWidth + 'px',
            height: playHeight + 'px',
          }"
        ></div>
      </div>

      <img
        class="img"
        :style="{ marginBottom: `${mar8}px` }"
        src="./assets/play.png"
        alt=""
      />
      <img class="img" src="./assets/event.png" alt="" />
      <img
        class="img"
        :style="{ marginTop: `${mar9}px`, paddingBottom: `${mar10}px` }"
        src="./assets/bottom.png"
        alt=""
      />
    </div>
    <!-- 区块3 end -->
    <!-- 公安备案 -->
    <div class="pic">
      <img src="./assets/pic.png" alt="" />
      沪公网安备 31010502006202号
    </div>
    <!-- 视频 -->
    <div class="video" :class="{ videoShow, angle }">
      <div class="close" @click="hideVideo"></div>
      <!-- <dir>{{ angle }}</dir> -->
      <video
        v-show="v1"
        class="v1"
        src="./assets/media.mp4"
        controls
        ref="video1"
      ></video>
      <video
        v-show="v2"
        class="v2"
        src="./assets/startAnimate.mp4"
        controls
        ref="video2"
      ></video>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      // 导航高度
      nav: 0,
      fixed: false,
      width: 0,
      height: 0,
      navTop: 0,
      // 详情高度
      descTop: 0,
      // 团队高度
      teamTop: 0,
      // margin
      mar1: 0,
      mar2: 0,
      mar3: 0,
      mar4: 0,
      mar5: 0,
      mar6: 0,
      mar7: 0,
      mar8: 0,
      mar9: 0,
      mar10: 0,
      // 视频
      videoShow: false,
      angle: false,
      time: false,
      v1: true,
      v2: true,
      playTop: 0,
      playWidth: 0,
      playHeight: 0,
    }
  },
  created() {
    // 悬浮大小
    this.setFixedSize()
    this.setFixedPlaySize()
    // 图片位置
    this.setTop(377, 'nav')
    this.setTop(43.5, 'navTop')
    this.setTop(902, 'descTop')
    this.setTop(1493, 'teamTop')
    this.setTop(1403, 'playTop')
    // 间距
    this.setTop(114, 'mar1')
    this.setTop(26, 'mar2')
    this.setTop(20, 'mar3')
    this.setTop(100, 'mar4')
    this.setTop(134, 'mar5')
    this.setTop(118, 'mar6')
    this.setTop(130, 'mar7')
    this.setTop(118, 'mar8')
    this.setTop(94, 'mar9')
    this.setTop(138, 'mar10')
    // 屏幕适应
    window.addEventListener('resize', (e) => {
      this.setFixedSize()
      this.setFixedPlaySize()
      this.setTop(377, 'nav')
      this.setTop(43.5, 'navTop')
      this.setTop(902, 'descTop')
      this.setTop(1493, 'teamTop')
      this.setTop(1403, 'playTop')
      this.setTop(114, 'mar1')
      this.setTop(26, 'mar2')
      this.setTop(20, 'mar3')
      this.setTop(100, 'mar4')
      this.setTop(134, 'mar5')
      this.setTop(118, 'mar6')
      this.setTop(130, 'mar7')
      this.setTop(118, 'mar8')
      this.setTop(94, 'mar9')
      this.setTop(138, 'mar10')
    })
    // 悬浮nav
    window.addEventListener('scroll', (e) => {
      if (window.scrollY != undefined) {
        this.setFixed(window.scrollY)
      } else if (document.documentElement.scrollTop != undefined) {
        this.setFixed(document.documentElement.scrollTop)
      }
    })
  },
  methods: {
    setTop(top, attr) {
      let body = document.documentElement
      if (body.clientWidth >= 750) {
        this[attr] = top
        return
      } else if (body.clientWidth <= 375) {
        this[attr] = top / 2
        return
      }
      this[attr] = (body.clientWidth / 750) * top
    },
    setFixed(scrollTop) {
      if (scrollTop >= this.nav) {
        this.fixed = true
      } else {
        this.fixed = false
        this.setTop(377, 'nav')
      }
    },
    setFixedSize() {
      let body = document.documentElement
      if (body.clientWidth >= 750) {
        this.width = 750
        this.height = 510
        return
      } else if (body.clientWidth <= 375) {
        this.width = 375
        this.height = 255
        return
      }
      this.width = (body.clientWidth / 750) * 750
      this.height = this.width * 0.68
    },
    setFixedPlaySize() {
      let body = document.documentElement
      if (body.clientWidth >= 750) {
        this.playWidth = 205
        this.playHeight = 356
        return
      } else if (body.clientWidth <= 375) {
        this.playWidth = 102.5
        this.playHeight = 178
        return
      }
      this.playWidth = (body.clientWidth / 750) * 205
      this.playHeight = this.playWidth * 1.73658
    },
    showVideo() {
      this.videoShow = true
      this.$refs.video1.play()
      this.$refs.video2.play()
      this.setAngle()
    },
    hideVideo() {
      this.videoShow = false
      this.$refs.video1.pause()
      this.$refs.video2.pause()
    },
    setAngle() {
      if (!this.time) {
        this.time = setInterval(() => {
          this.angle = screen.orientation.angle === 90
        }, 100)
      }
    },
  },
}
</script>

<style lang="less">
html,
body {
  margin: 0;
  padding: 0;
}

img {
  display: block;
}

.part-img {
  width: 100%;
  position: absolute;
}

#app {
  width: 100%;
  min-width: 375px;
  max-width: 750px;
  margin: 0 auto;
  background: url('./assets/back2.jpg') repeat-y;
  background-size: 100%;

  // 头部
  .header {
    position: relative;

    // 背景
    .back {
      width: 100%;
    }
    .nav {
      width: 100%;
      max-width: 750px;
      z-index: 998;
      position: absolute;
      background: url('./assets/nav.png') no-repeat left top/100%;

      &.fixed {
        position: fixed;
        top: 0 !important;
      }
    }
    // 锚点
    .nav1,
    .nav2,
    .nav3 {
      cursor: pointer;
      width: 28.9333%;
      position: absolute;
    }
    .nav1 {
      left: 2.4%;
    }
    .nav2 {
      left: 35.6%;
    }
    .nav3 {
      right: 2.1%;
    }
  }

  // 区块
  .part2,
  .part3 {
    width: 100%;
    position: relative;
    .img {
      width: 100%;
    }

    .v2play {
      position: relative;

      .play {
        z-index: 111;
        position: absolute;
        left: 8.1%;
      }
    }
  }
  // 链接位置
  #p1,
  #p2,
  #p3 {
    position: absolute;
  }

  .pic {
    color: #999;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 8px;
    font-size: 14px;
    img{
      padding-right: 3px;
    }
  }

  // 视频
  .video {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    z-index: 9999999;
    left: -100vw;
    top: 0;

    &.videoShow {
      left: 0;
    }

    .close {
      z-index: 10;
      width: 42px;
      height: 42px;
      position: absolute;
      top: 8px;
      right: 8px;
      background: url('./assets/close.png') no-repeat center/contain;
    }

    .v1 {
      width: 86%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .v2 {
      width: 86%;
      max-width: 414px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
